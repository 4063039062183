







































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import UserGroup from '@/core/interfaces/UserGroup';
import UserGroupForm from '@/components/users/UserGroupForm.vue';
import UserTabs from '@/components/users/UserTabs.vue';
import { VNode } from 'vue';
import { BBadge } from 'bootstrap-vue';
import Role from '@/core/interfaces/Role';

@Component({
    components: { UserTabs, Overview, UserGroupForm },
})
export default class UserGroupsView extends OverviewTable<UserGroup> {
    @Getter('userGroups')
    userGroups!: UserGroup[];

    @Action('deleteUserGroup')
    deleteAction!: (id: number) => Promise<void>;

    editUserGroup: Partial<UserGroup> | null = null;

    search = '';

    get columns(): LelyTableColumnsConfig<UserGroup> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
                sub: userGroup => `${this.$store.getters.usersByUserGroup(userGroup.id).length} users`,
            },
            {
                value: userGroup => this.createRoles(userGroup),
                header: 'Roles',
                shrink: true,
            },
        ];
    }

    openForm(userGroup: UserGroup): void {
        this.editUserGroup = userGroup;
        this.$bvModal.show('user-group-form');
    }

    showCreateModal(): void {
        this.editUserGroup = {};
        this.$bvModal.show('user-group-form');
    }

    get userGroupsFiltered(): UserGroup[] {
        return this.userGroups.filter(userGroup => userGroup.name.toLowerCase().includes(this.search.toLowerCase()));
    }

    createRoles(userGroup: UserGroup): VNode {
        const h = this.$createElement;

        return h('div', this.$store.getters.rolesByUserGroup(userGroup.id).map((role: Role) => h(BBadge, {
            class: 'ml-1 badge--secondary',
        }, [role.name])));
    }
}

