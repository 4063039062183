























import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import VehicleType from '@/core/interfaces/VehicleType';
import axios from 'axios';

import ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { BFormFile } from 'bootstrap-vue';
import arrayUnique from 'array-unique';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';

type TypeValue = 'vehicle-phases' | 'notification-types' | 'app';

@Component({
    components: {},
})
export default class VehicleTypeEditLocale extends Vue {
    @Prop({ required: true })
    vehicleType!: VehicleType;

    types: {name: string, value: TypeValue}[] = [
        {
            name: 'Phase',
            value: 'vehicle-phases',
        },
        {
            name: 'Notification type',
            value: 'notification-types',
        },
        {
            name: 'App',
            value: 'app',
        },
    ];

    type: TypeValue = 'vehicle-phases';

    locales: { language: string, code: string }[] = [];

    translations: Record<string, Record<string, string>> = {};

    uploadFile: File | null = null;

    async mounted() {
        this.locales = (await axios.get('https://lely-alarmcenter-backend.azurewebsites.net/api/web/locales')).data;
    }

    get translationRows(): { key: string; translations: Record<string, string> }[] {
        return this.uniqueTranslations.map(translation => ({
            key: translation,
            translations: this.locales.reduce((acc: Record<string, string>, locale) => {
                acc[locale.code] = this.findTranslation(translation, locale.code);

                return acc;
            }, {}),
        }));
    }

    get columns(): LelyTableColumnsConfig<{ key: string; translations: Record<string, string> }> {
        const h = this.$createElement;
        return [
            {
                value: 'key',
                header: this.selectedType.name,
                shrink: true,
            },
            {
                header: 'Translation',
                value: value => h(
                    'ul',
                    {
                        class: 'm-0',
                    },
                    Object.entries(value.translations)
                        .map(([locale, translation]) => h('li', `${locale}: ${translation}`)),

                ),
            },
        ];
    }

    findTranslation(key: string, locale: string): string {
        return this.translations[locale].hasOwnProperty(key) ? this.translations[locale][key] : '';
    }

    get uniqueTranslations(): string[] {
        return arrayUnique(Object.values(this.translations)
            .flatMap(translationsForLocale => Object.keys(translationsForLocale)))
            .filter(translation => translation !== '')
            .sort((a, b) => (['vehicle-phases', 'app'].includes(this.type)
                ? a.toLowerCase().localeCompare(b.toLowerCase())
                : Number(a) - Number(b)));
    }

    get selectedType(): { name: string, value: TypeValue } {
        const type = this.types.find(type => type.value === this.type);

        if (!type) {
            throw new Error('Invalid type');
        }

        return type;
    }

    getUrl(locale: string): string {
        return `https://lely-alarmcenter-backend.azurewebsites.net/api/web/vehicle-types/${this.vehicleType.id}/locales/${locale}/${this.type}`;
    }

    @Watch('type')
    @Watch('locales')
    async fetchAllTranslations(): Promise<void> {
        this.translations = {};

        const translations = await Promise.all(this.locales.map(async locale => (await axios.get<Record<string, string>>(this.getUrl(locale.code))).data));
        this.translations = this.locales.reduce((acc: Record<string, Record<string, string>>, locale, localeIdx) => {
            acc[locale.code] = translations[localeIdx];

            return acc;
        }, {});
    }

    async downloadPhases(): Promise<void> {
        const phases: string[] = (await axios.get('https://lely-alarmcenter-backend.azurewebsites.net/api/web/vehicle-phases'))
            .data
            .filter((phase: any) => phase.vehicle_type_id === this.vehicleType.id)
            .map((phase: any) => phase.code_name);

        await this.download(phases, 'Vehicle phases', `phases-${this.vehicleType.name}.xlsx`);
    }

    async downloadNotificationTypes(): Promise<void> {
        const notificationTypes: string[] = (await axios.get('https://lely-alarmcenter-backend.azurewebsites.net/api/web/notification-types'))
            .data
            .filter((notificationType: any) => notificationType.vehicle_type_id === this.vehicleType.id)
            .map((notificationType: any) => String(notificationType.code));

        await this.download(notificationTypes, 'Notification types', `notification-types-${this.vehicleType.name}.xlsx`);
    }

    async downloadApp(): Promise<void> {
        const translations = this.uniqueTranslations;

        await this.download(translations, 'Translations', `app-translations-${this.vehicleType.name}.xlsx`);
    }

    async download(rows: string[], worksheet: string, filename: string): Promise<void> {
        const workbook = new ExcelJS.Workbook();
        const sheet = workbook.addWorksheet(worksheet);
        sheet.insertRow(1, [worksheet, ...this.locales.map(locale => `${locale.language} (${locale.code})`)]);

        const allUniqueKeys = arrayUnique([...rows, ...this.uniqueTranslations])
            .filter(key => key !== '')
            .sort((a, b) => (this.type === 'vehicle-phases'
                ? a.toLowerCase().localeCompare(b.toLowerCase())
                : Number(a) - Number(b)));

        allUniqueKeys.forEach((translation, translationIdx) => {
            sheet.insertRow(translationIdx + 2, [translation, ...this.locales
                .map(locale => (this.translations[locale.code].hasOwnProperty(translation)
                    ? this.translations[locale.code][translation]
                    : ''
                ))]);
        });

        const xls64 = await workbook.xlsx.writeBuffer();

        saveAs(
            new Blob([xls64], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }),
            filename,
        );
    }

    async downloadTemplate(): Promise<void> {
        await this.fetchAllTranslations();

        switch (this.type) {
            case 'vehicle-phases':
                return this.downloadPhases();
            case 'notification-types':
                return this.downloadNotificationTypes();
            case 'app':
                return this.downloadApp();
            default:
                throw new Error('Invalid type');
        }
    }

    @Watch('uploadFile')
    async uploadTemplate(): Promise<void> {
        if (!this.uploadFile) {
            return;
        }

        const values = await this.readExcel(this.uploadFile);

        (this.$refs.upload as BFormFile).reset();

        await this.upload(values);

        this.uploadFile = null;
    }

    async upload(values: Record<string, Record<string, string>>): Promise<void> {
        await Promise.all(Object.entries(values).map(([locale, translations]) => axios.put(this.getUrl(locale), translations)));
        await this.fetchAllTranslations();
    }

    async readExcel(file: File): Promise<Record<string, Record<string, string>>> {
        return new Promise((resolve) => {
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);

            reader.onload = async() => {
                const workbook = new ExcelJS.Workbook();
                const file = await workbook.xlsx.load(reader.result as any);
                const sheet = await file.getWorksheet(1);

                const result: Record<string, Record<string, string>> = {};

                this.locales.forEach(locale => {
                    result[locale.code] = {};
                });

                sheet.eachRow((row, rowIdx) => {
                    if (rowIdx === 1) {
                        return;
                    }

                    this.locales.forEach((locale, localeIdx) => {
                        const key = String(row.getCell(1).value);
                        result[locale.code][key] = String(row.getCell(localeIdx + 2).value);
                    });
                });

                resolve(result);
            };
        });
    }
}

