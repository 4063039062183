import '@tec/frontend-vue-shared/src/assets/lely-red-rules/lely-styling/lely-stylesheet.scss';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import LelyFrontendVueShared, { LelyAuth } from '@tec/frontend-vue-shared';

import BootstrapVue from 'bootstrap-vue';
import axios from 'axios';
import Vue from 'vue';
import router from './router';
import store from './store';
import './core/HttpErrorInterceptor';
import '@/filters';
import App from './App.vue';

Vue.use(BootstrapVue);
Vue.use(LelyFrontendVueShared);

Vue.config.productionTip = false;

axios.defaults.baseURL = process.env.VUE_APP_API_URL || '/api';

if (LelyAuth.isAuthenticated()) {
    axios.defaults.headers.common.Authorization = `Bearer ${LelyAuth.getAccessToken()}`;

    store.dispatch('sync')
        .then();
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');
