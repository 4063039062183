




















































import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';
import Role from '@/core/interfaces/Role';

@Component({
    components: { FormFooter },
})
export default class RoleForm extends FormBase<Role> {
    @Action('saveRole')
    saveAction!: (role: Partial<Role>) => Promise<Role>;

    name = '';

    assignable = false;

    notifyable = false;

    admin = false;

    developer = false;

    alarmcenter = false;

    kniveset = false;

    @Watch('initial', { immediate: true })
    async setFromInitial(): Promise<void> {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
        this.assignable = this.initial.assignable || false;
        this.notifyable = this.initial.notifyable || false;
        this.admin = this.initial.admin || false;
        this.developer = this.initial.developer || false;
        this.alarmcenter = this.initial.alarmcenter || false;
        this.kniveset = this.initial.kniveset || false;
    }

    get parseForm(): Partial<Role> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
            assignable: this.assignable,
            notifyable: this.notifyable,
            admin: this.admin,
            developer: this.developer,
            alarmcenter: this.alarmcenter,
            kniveset: this.kniveset,
        };
    }

    submitted(): void {
        this.$emit('submitted');
    }
}

