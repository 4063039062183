



















import { Component, Prop } from 'vue-property-decorator';
import Locale from '@/core/interfaces/Locale';
import { Getter } from 'vuex-class';
import Application from '@/core/interfaces/Application';
import FormFooter from '@/components/FormFooter.vue';
import JsonEditor from '@/components/JsonEditor.vue';
import { mixins } from 'vue-class-component';
import EditsJson from '@/mixins/EditsJson';

@Component({
    components: { JsonEditor, FormFooter },
})
export default class ApplicationTranslations extends mixins(EditsJson) {
    @Prop({ required: true })
    application!: Application;

    @Getter('locales')
    locales!: Locale[];

    selectedLocale = '';

    get url(): string {
        if (!this.selectedLocale) {
            return '';
        }

        return `/admin/applications/${this.application.code}/locales/${this.selectedLocale}`;
    }

    get deleteOnEmpty(): boolean {
        return true;
    }
}

