







































































































import { Component, Watch } from 'vue-property-decorator';
import Vehicle from '@/core/interfaces/Vehicle';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action, Getter } from 'vuex-class';
import Farm from '@/core/interfaces/Farm';
import VehicleType from '@/core/interfaces/VehicleType';
import Barn from '@/core/interfaces/Barn';
import AuroraHubZone from '@/core/interfaces/AuroraHubZone';

@Component({
    components: { FormFooter },
})
export default class VehicleForm extends FormBase<Vehicle> {
    @Action('saveVehicle')
    saveAction!: (barn: Partial<Vehicle>) => Promise<Vehicle>;

    @Getter('farms')
    farms!: Farm[];

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Getter('barnsByFarm')
    barnsByFarm!: (id: number) => Barn[];

    @Getter('auroraHubZonesByFarm')
    auroraHubZonesByFarm!: (id: number) => AuroraHubZone[];

    farmId = 0;

    barnId: number | null = null;

    auroraHubZoneId: number | null = null;

    vehicleTypeId = 0;

    name = '';

    resetAccessToken = false;

    sim = false;

    mfrId: number | null = null;

    mfrVehicleTypeId = 0;

    auroraVehicleTypeId = 0;

    btClassic = false;

    mounted(): void {
        this.mfrVehicleTypeId = this.vehicleTypes.find(vt => vt.name === 'mfr')?.id || 0;
        this.auroraVehicleTypeId = this.vehicleTypes.find(vt => vt.name === 'aurora')?.id || 0;
    }

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
        this.farmId = this.initial.farm_id || 0;
        this.barnId = this.initial.barn_id || null;
        this.vehicleTypeId = this.initial.vehicle_type_id || 0;
        this.sim = this.initial.sim || false;
        this.mfrId = this.initial.mfr_id || null;
        this.btClassic = this.initial.bt_classic || false;
        this.auroraHubZoneId = this.initial.aurora_hub_zone_id || null;
    }

    get parseForm(): Partial<Vehicle> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
            farm_id: this.farmId,
            barn_id: this.barnId,
            vehicle_type_id: this.vehicleTypeId,
            access_token: this.resetAccessToken ? null : this.initial.access_token,
            sim: Boolean(this.sim),
            mfr_id: this.vehicleTypeId === this.mfrVehicleTypeId ? this.mfrId : null,
            bt_classic: Boolean(this.btClassic),
            aurora_hub_zone_id: this.vehicleTypeId === this.auroraVehicleTypeId ? this.auroraHubZoneId : null,
        };
    }

    submitted(): void {
        this.$emit('submitted');
    }

    @Watch('farmId')
    resetBarn(): void {
        this.barnId = null;
    }

    get auroraHubZones(): AuroraHubZone[] {
        return this.auroraHubZonesByFarm(this.farmId);
    }
}

