





























































import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action, Getter } from 'vuex-class';
import UserGroup from '@/core/interfaces/UserGroup';
import VehicleType from '@/core/interfaces/VehicleType';
import Role from '@/core/interfaces/Role';
import FarmGroup from '@/core/interfaces/FarmGroup';
import arrayUnique from 'array-unique';
import User from '@/core/interfaces/User';

@Component({
    components: { FormFooter },
})
export default class UserGroupForm extends FormBase<UserGroup> {
    @Action('saveUserGroup')
    saveAction!: (userGroup: Partial<UserGroup>) => Promise<UserGroup>;

    @Getter('roles')
    roles!: Role[];

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Getter('farmGroups')
    farmGroups!: FarmGroup;

    @Getter('users')
    users!: User[];

    @Getter('farmGroupsByUserGroup')
    farmGroupsByUserGroup!: (id: number) => FarmGroup[];

    @Getter('rolesByUserGroup')
    rolesByUserGroup!: (id: number) => Role[];

    @Getter('vehicleTypesByUserGroup')
    vehicleTypesByUserGroup!: (id: number) => VehicleType[];

    @Getter('usersByUserGroup')
    usersByUserGroup!: (id: number) => User[];

    name = '';

    farmGroupIds: number[] = [];

    vehicleTypeIds: number[] = [];

    roleIds: number[] = [];

    userIds: number[] = [];

    @Watch('initial', { immediate: true })
    async setFromInitial(): Promise<void> {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';

        this.roleIds = this.initial.id
            ? arrayUnique(this.rolesByUserGroup(this.initial.id).map((role) => role.id))
            : [];

        this.vehicleTypeIds = this.initial.id
            ? arrayUnique(this.vehicleTypesByUserGroup(this.initial.id).map((vehicleType) => vehicleType.id))
            : [];

        this.farmGroupIds = this.initial.id
            ? arrayUnique(this.farmGroupsByUserGroup(this.initial.id).map((farmGroup) => farmGroup.id))
            : [];

        this.userIds = this.initial.id
            ? arrayUnique(this.usersByUserGroup(this.initial.id).map((user) => user.id))
            : [];
    }

    async afterSave(userGroup: UserGroup): Promise<void> {
        await Promise.all([
            await this.$store.dispatch('saveRoleUserGroups', {
                userGroupId: userGroup.id,
                roleIds: this.roleIds,
            }),
            await this.$store.dispatch('saveFarmGroupUserGroups', {
                userGroupId: userGroup.id,
                farmGroupIds: this.farmGroupIds,
            }),
            await this.$store.dispatch('saveVehicleTypeUserGroups', {
                userGroupId: userGroup.id,
                vehicleTypeIds: this.vehicleTypeIds,
            }),
            await this.$store.dispatch('saveUserUserGroupsByUserGroup', {
                userGroupId: userGroup.id,
                userIds: this.userIds,
            }),
        ]);
    }

    get parseForm(): Partial<UserGroup> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
        };
    }

    submitted(): void {
        this.$emit('submitted');
    }
}

