












import { Component, Prop, Vue } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import { Action, Getter } from 'vuex-class';
import VehicleAction from '@/core/interfaces/VehicleAction';
import AvailableVehicleTypeAction from '@/core/interfaces/AvailableVehicleTypeAction';

@Component({
    components: { FormFooter },
})
export default class FarmVehicleTypeActions extends Vue {
    @Prop({ type: Number, required: true })
    availableVehicleTypeId!: number;

    @Prop({ type: Number, required: true })
    farmId!: number;

    @Getter('vehicleActions')
    vehicleActions!: VehicleAction[];

    @Getter('availableActionsByAvailableVehicleType')
    availableActionsByAvailableVehicleType!: (id: number) => AvailableVehicleTypeAction[];

    @Action('saveAvailableVehicleTypeAction')
    saveAvailableVehicleTypeAction!: (payload: Partial<AvailableVehicleTypeAction>) => Promise<void>;

    @Action('deleteAvailableVehicleTypeAction')
    deleteAvailableVehicleTypeAction!: (id: number) => Promise<void>;

    selectedVehicleActions: number[] = [];

    mounted(): void {
        this.selectedVehicleActions = this.availableActionsByAvailableVehicleType(this.availableVehicleTypeId)
            .map(action => action.vehicle_action_id);
    }

    async submit(): Promise<void> {
        const current = this.availableActionsByAvailableVehicleType(this.availableVehicleTypeId);
        const currentActionIds = current.map(action => action.vehicle_action_id);

        const post = this.selectedVehicleActions
            .filter(action => !currentActionIds.includes(action))
            .map(action => this.saveAvailableVehicleTypeAction({
                farm_available_vehicle_type_id: this.availableVehicleTypeId,
                vehicle_action_id: action,
            }));

        const deletes = current.filter(action => !this.selectedVehicleActions.includes(action.vehicle_action_id))
            .map(action => this.deleteAvailableVehicleTypeAction(action.id));

        await Promise.all([...post, ...deletes]);

        this.$emit('submitted');
    }
}

