















































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Farm from '@/core/interfaces/Farm';
import Fence from '@/core/interfaces/Fence';
import Barn from '@/core/interfaces/Barn';
import VehicleType from '@/core/interfaces/VehicleType';
import Vehicle from '@/core/interfaces/Vehicle';
import OverviewTable from '@/mixins/OverviewTable';
import Overview from '@/components/Overview.vue';
import FarmForm from '@/components/farms/FarmForm.vue';

@Component({
    components: { FarmForm, Overview },
})
export default class FarmsView extends OverviewTable<Farm> {
    @Getter('farms')
    farms!: Farm[];

    @Getter('fencesByFarm')
    fencesByFarm!: (farmId: number) => Fence[];

    @Getter('barnsByFarm')
    barnsByFarm!: (farmId: number) => Barn[];

    @Getter('vehicleTypesByFarm')
    vehicleTypesByFarm!: (farmId: number) => VehicleType[];

    @Getter('vehiclesByFarm')
    vehiclesByFarm!: (farmId: number) => Vehicle[];

    @Action('deleteFarm')
    deleteAction!: (id: number) => Promise<void>;

    search = '';

    get columns(): LelyTableColumnsConfig<Farm> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                header: 'Farm',
                value: 'name',
                sub: farm => `${this.fencesByFarm(farm.id).length} fences / ${this.barnsByFarm(farm.id).length} barns`,
            },
            {
                header: 'Vehicles',
                value: farm => this.vehiclesDescription(farm),
            },
        ];
    }

    get farmActions(): LelyTableActionsConfig<Farm> {
        return [
            {
                name: 'delete',
                label: 'Delete',
                icon: 'lely-icon-trash',
                variant: 'warning',
            },
            {
                name: 'vehicles',
                label: farm => `Show vehicles (${this.vehiclesByFarm(farm.id).length})`,
                show: farm => this.vehiclesByFarm(farm.id).length > 0,
                icon: 'lely-icon-traffic',
            },
            {
                name: 'edit',
                label: 'Edit farm',
                icon: 'lely-icon-manage',
            },
        ];
    }

    vehiclesDescription(farm: Farm): string {
        return this.vehicleTypesByFarm(farm.id)
            .map(vehicleType => `
            ${vehicleType.name} (${this.vehiclesByFarm(farm.id).filter(vehicle => vehicle.vehicle_type_id === vehicleType.id).length})`)
            .join(', ');
    }

    get farmsFiltered(): Farm[] {
        return this.farms.filter(farm => farm.name.toLowerCase().includes(this.search.toLowerCase()));
    }
}

