




import { Component, Prop } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';

import VehicleType from '@/core/interfaces/VehicleType';
import JsonEditor from '@/components/JsonEditor.vue';
import { mixins } from 'vue-class-component';
import EditsJson from '@/mixins/EditsJson';

@Component({
    components: { JsonEditor, FormFooter },
})
export default class VehicleTypeForm extends mixins(EditsJson) {
    @Prop({ required: true })
    vehicleType!: VehicleType;

    get url(): string {
        return `https://lely-alarmcenter-backend.azurewebsites.net/api/web/vehicle-types/${this.vehicleType.id}/dashboard-definition`;
    }
}

