















import { Component, Watch } from 'vue-property-decorator';
import Barn from '@/core/interfaces/Barn';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';

@Component({
    components: { FormFooter },
})
export default class BarnForm extends FormBase<Barn> {
    @Action('saveBarn')
    saveAction!: (barn: Partial<Barn>) => Promise<Barn>;

    name = '';

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.name = this.initial.name || '';
    }

    get parseForm(): Partial<Barn> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            name: this.name,
        };
    }

    submitted(): void {
        this.$emit('submitted');
    }
}

