import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component({})
export default class FormBase<T> extends Vue {
    @Prop({
        default: () => ({}),
    })
    initial!: Partial<T>;

    saving = false;

    // @ts-ignore
    get parseForm(): Partial<T> {
        // noop;
    }

    // @ts-ignore
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    saveAction(data: Partial<T>): Promise<T> {
        // noop;
    }

    // @ts-ignore
    submitted(): void {
        // noop;
    }

    // @ts-ignore
    async beforeSave(): Promise<void> {
        // noop;
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    async afterSave(data: T): Promise<void> {
        // noop;
    }

    async submit(): Promise<void> {
        this.saving = true;
        let data;

        await this.beforeSave();

        try {
            data = await this.saveAction(this.parseForm);
            await this.afterSave(data);
        } catch (err) {
            // noop
        } finally {
            this.saving = false;
        }

        this.submitted();
    }

    get exists(): boolean {
        if (!this.initial) {
            return false;
        }

        return this.initial.hasOwnProperty('id');
    }
}
