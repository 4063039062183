
































import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import { PrismEditor } from 'vue-prism-editor';
import Prism from 'prismjs';

import 'prismjs/components/prism-json';
import 'vue-prism-editor/dist/prismeditor.min.css';
import 'prismjs/themes/prism-tomorrow.css';

const spaces = 2;

@Component({
    components: { FormFooter, PrismEditor },
})
export default class JsonEditor extends Vue {
    @Prop({ required: true })
    value!: string;

    @Prop({ type: Boolean })
    loading!: boolean;

    @Prop({ type: Boolean })
    allowEmpty!: boolean;

    currentValue: string | null = null;

    origValue: string | null = null;

    @Watch('value', { immediate: true })
    updateCurrentValue(): void {
        this.currentValue = this.value;
        this.origValue = this.value;
    }

    async save(): Promise<void> {
        if (!this.validJSON) {
            throw new Error('Invalid JSON');
        }

        this.$emit('save', this.currentValue);
    }

    highlighter(code: string): string {
        return Prism.highlight(code, Prism.languages.json, 'json');
    }

    reset(): void {
        this.currentValue = this.origValue;
    }

    format(): void {
        if (!this.currentValue) {
            return;
        }

        this.currentValue = JSON.stringify(JSON.parse(this.currentValue), null, spaces);
    }

    get validJSON(): boolean {
        if (this.allowEmpty && this.currentValue === '') {
            return true;
        }

        if (!this.currentValue || this.currentValue === '') {
            return false;
        }

        try {
            JSON.parse(this.currentValue);
        } catch (e) {
            return false;
        }

        return true;
    }
}

