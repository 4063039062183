






































import { Component } from 'vue-property-decorator';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { Action, Getter } from 'vuex-class';
import Barn from '@/core/interfaces/Barn';
import { mixins } from 'vue-class-component';
import UsesFarmId from '@/mixins/UsesFarmId';
import OverviewTable from '@/mixins/OverviewTable';
import Fence from '@/core/interfaces/Fence';
import BarnForm from '@/components/farms/BarnForm.vue';
import BarnFences from '@/components/farms/BarnFences.vue';
import Overview from '@/components/Overview.vue';

@Component({
    components: { Overview, BarnForm, BarnFences },
})
export default class FarmBarnsView extends mixins<UsesFarmId, OverviewTable<Barn>>(UsesFarmId, OverviewTable) {
    @Action('deleteBarn')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('barnsByFarm')
    barnsByFarm!: (id: number) => Barn[];

    @Getter('fencesByBarn')
    fencesByBarn!: (id: number) => Fence[];

    editBarn: Partial<Barn> | null = null;

    fencesBarn: Barn | null = null;

    get barns(): Barn[] {
        if (!this.farm) {
            return [];
        }

        return this.barnsByFarm(this.farm.id);
    }

    get barnActions(): LelyTableActionsConfig<Barn> {
        return [
            ...this.actions,
            {
                label: 'Fences',
                name: 'fences',
                icon: 'lely-icon-list',
            },
        ];
    }

    get columns(): LelyTableColumnsConfig<Barn> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                header: 'Barn',
                value: 'name',
                sub: barn => `${this.fencesByBarn(barn.id).length} fences`,
            },
        ];
    }

    showFences(barn: Barn): void {
        this.fencesBarn = barn;
        this.$bvModal.show('barn-fences');
    }

    showBarn(barn: Barn): void {
        this.editBarn = barn;
        this.$bvModal.show('barn-form');
    }

    barnsUpdated(): void {
        this.$bvModal.hide('barn-form');
        this.editBarn = null;
    }

    addBarn(): void {
        this.editBarn = {
            farm_id: this.farmId,
        } as Partial<Barn>;

        this.$bvModal.show('barn-form');
    }
}

