



























import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import UserForm from '@/components/users/UserForm.vue';
import UserTabs from '@/components/users/UserTabs.vue';
import Role from '@/core/interfaces/Role';
import RoleForm from '@/components/users/RoleForm.vue';
import { VNode } from 'vue';
import { BBadge } from 'bootstrap-vue';

@Component({
    components: {
        RoleForm, Overview, UserForm, UserTabs,
    },
})
export default class UserRolesView extends OverviewTable<Role> {
    @Getter('roles')
    roles!: Role[];

    @Action('deleteRole')
    deleteAction!: (id: number) => Promise<void>;

    editRole: Partial<Role> | null = null;

    get columns(): LelyTableColumnsConfig<Role> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
            },
            {
                value: user => this.createFlags(user),
                header: 'Permissions',
                shrink: true,
            },
        ];
    }

    get roleActions(): LelyTableActionsConfig<Role> {
        return [
            {
                name: 'delete',
                label: 'Delete',
                icon: 'lely-icon-trash',
                variant: 'warning',
                disabled: item => item.is_system,
            },
            {
                name: 'edit',
                label: 'Edit',
                icon: 'lely-icon-edit',
                disabled: item => item.is_system,
            },
        ];
    }

    openRoleForm(role: Role): void {
        this.editRole = role;
        this.$bvModal.show('role-form');
    }

    showCreateModal(): void {
        this.editRole = {};
        this.$bvModal.show('role-form');
    }

    createFlags(role: Role): VNode {
        const h = this.$createElement;
        const flags = [];

        if (role.developer) {
            flags.push('Developer');
        }

        if (role.admin) {
            flags.push('Admin');
        }

        if (role.kniveset) {
            flags.push('Kniveset');
        }

        if (role.alarmcenter) {
            flags.push('Alarmcenter');
        }

        return h('div', flags.map(role => h(BBadge, {
            class: 'ml-1 badge--secondary',
        }, [role])));
    }
}

