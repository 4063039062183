








import { Component, Watch } from 'vue-property-decorator';
import FarmForm from '@/components/farms/FarmForm.vue';
import UsesFarmId from '@/mixins/UsesFarmId';
import Farm from '@/core/interfaces/Farm';

@Component({
    components: { FarmForm },
})
export default class FarmFormView extends UsesFarmId {
    initial: Farm | null = null;

    @Watch('farmId', { immediate: true })
    setFarm() {
        this.initial = this.farm;
    }
}

