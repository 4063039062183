import RootState from '@/store/rootState';
import Farm from '@/core/interfaces/Farm';
import Barn from '@/core/interfaces/Barn';
import User from '@/core/interfaces/User';
import VehicleType from '@/core/interfaces/VehicleType';
import Vehicle from '@/core/interfaces/Vehicle';
import Fence from '@/core/interfaces/Fence';
import FeedType from '@/core/interfaces/FeedType';
import AvailableVehicleType from '@/core/interfaces/AvailableVehicleType';
import Vue from 'vue';
import VehicleAction from '@/core/interfaces/VehicleAction';
import AvailableVehicleTypeAction from '@/core/interfaces/AvailableVehicleTypeAction';
import Application from '@/core/interfaces/Application';
import Locale from '@/core/interfaces/Locale';
import ArbiterZone from '@/core/interfaces/ArbiterZone';
import TimeZone from '@/core/interfaces/TimeZone';
import UserGroup from '@/core/interfaces/UserGroup';
import FarmGroup from '@/core/interfaces/FarmGroup';
import Role from '@/core/interfaces/Role';
import UserUserGroup from '@/core/interfaces/UserUserGroup';
import FarmFarmGroup from '@/core/interfaces/FarmFarmGroup';
import RoleUserGroup from '@/core/interfaces/RoleUserGroup';
import VehicleTypeUserGroup from '@/core/interfaces/VehicleTypeUserGroup';
import FarmGroupUserGroup from '@/core/interfaces/FarmGroupUserGroup';
import AuroraHubZone from '@/core/interfaces/AuroraHubZone';

const updateItemInState = <T extends { id: number }>(arr: T[], payload: T) => {
    const index = arr.findIndex(item => item.id === payload.id);

    if (index > -1) {
        Vue.set(arr, index, payload);
    } else {
        arr.push(payload);
    }
};

const removeFromState = <T extends { id: number }>(arr: T[], payload: number) => {
    arr.splice(arr.findIndex(item => item.id === payload), 1);
};

export const addError = (state: RootState, payload: string): void => {
    state.errors.push(payload);
};

export const resetErrors = (state: RootState): void => {
    state.errors = [];
};

export const setFarms = (state: RootState, payload: Farm[]): void => {
    state.farms = payload;
};

export const setBarns = (state: RootState, payload: Barn[]): void => {
    state.barns = payload;
};

export const setUsers = (state: RootState, payload: User[]): void => {
    state.users = payload;
};

export const setVehicleTypes = (state: RootState, payload: VehicleType[]): void => {
    state.vehicleTypes = payload;
};

export const setAvailableVehicleTypes = (state: RootState, payload: AvailableVehicleType[]): void => {
    state.availableVehicleTypes = payload;
};

export const saveAvailableVehicleType = (state: RootState, payload: AvailableVehicleType): void => {
    updateItemInState(state.availableVehicleTypes, payload);
};

export const removeAvailableVehicleType = (state: RootState, payload: number): void => {
    removeFromState(state.availableVehicleTypes, payload);
};

export const setVehicles = (state: RootState, payload: Vehicle[]): void => {
    state.vehicles = payload;
};

export const setFences = (state: RootState, payload: Fence[]): void => {
    state.fences = payload;
};

export const setFeedTypes = (state: RootState, payload: FeedType[]): void => {
    state.feedTypes = payload;
};

export const removeFarm = (state: RootState, payload: number): void => {
    removeFromState(state.farms, payload);
};

export const removeBarn = (state: RootState, payload: number): void => {
    removeFromState(state.barns, payload);
};

export const removeFence = (state: RootState, payload: number): void => {
    removeFromState(state.fences, payload);
};

export const saveFarm = (state: RootState, payload: Farm): void => {
    updateItemInState(state.farms, payload);
};

export const saveBarn = (state: RootState, payload: Barn): void => {
    updateItemInState(state.barns, payload);
};

export const saveFeedType = (state: RootState, payload: FeedType): void => {
    updateItemInState(state.feedTypes, payload);
};

export const removeFeedType = (state: RootState, payload: number): void => {
    removeFromState(state.feedTypes, payload);
};

export const saveFence = (state: RootState, payload: Fence): void => {
    updateItemInState(state.fences, payload);
};

export const saveVehicle = (state: RootState, payload: Vehicle): void => {
    updateItemInState(state.vehicles, payload);
};

export const removeVehicle = (state: RootState, payload: number): void => {
    removeFromState(state.vehicles, payload);
};

export const saveUser = (state: RootState, payload: User): void => {
    updateItemInState(state.users, payload);
};

export const removeUser = (state: RootState, payload: number): void => {
    removeFromState(state.users, payload);
};

export const saveVehicleType = (state: RootState, payload: VehicleType): void => {
    updateItemInState(state.vehicleTypes, payload);
};

export const removeVehicleType = (state: RootState, payload: number): void => {
    removeFromState(state.vehicleTypes, payload);
};

export const setVehicleActions = (state: RootState, payload: VehicleAction[]): void => {
    state.vehicleActions = payload;
};

export const saveVehicleAction = (state: RootState, payload: VehicleAction): void => {
    updateItemInState(state.vehicleActions, payload);
};

export const removeVehicleAction = (state: RootState, payload: number): void => {
    removeFromState(state.vehicleActions, payload);
};

export const setAvailableVehicleTypeActions = (state: RootState, payload: AvailableVehicleTypeAction[]): void => {
    state.availableVehicleTypeActions = payload;
};

export const saveAvailableVehicleTypeAction = (state: RootState, payload: AvailableVehicleTypeAction): void => {
    updateItemInState(state.availableVehicleTypeActions, payload);
};

export const removeAvailableVehicleTypeAction = (state: RootState, payload: number): void => {
    removeFromState(state.availableVehicleTypeActions, payload);
};

export const setApplications = (state: RootState, payload: Application[]): void => {
    state.applications = payload;
};

export const saveApplication = (state: RootState, payload: Application): void => {
    updateItemInState(state.applications, payload);
};

export const removeApplication = (state: RootState, payload: number): void => {
    removeFromState(state.applications, payload);
};

export const setLocales = (state: RootState, payload: Locale[]): void => {
    state.locales = payload;
};

export const saveLocale = (state: RootState, payload: Locale): void => {
    updateItemInState(state.locales, payload);
};

export const removeLocale = (state: RootState, payload: number): void => {
    removeFromState(state.locales, payload);
};

export const setArbiterZones = (state: RootState, payload: ArbiterZone[]): void => {
    state.arbiterZones = payload;
};

export const saveArbiterZone = (state: RootState, payload: ArbiterZone): void => {
    updateItemInState(state.arbiterZones, payload);
};

export const removeArbiterZone = (state: RootState, payload: number): void => {
    removeFromState(state.arbiterZones, payload);
};

export const setAuroraHubZones = (state: RootState, payload: AuroraHubZone[]): void => {
    state.auroraHubZones = payload;
};

export const saveAuroraHubZone = (state: RootState, payload: AuroraHubZone): void => {
    updateItemInState(state.auroraHubZones, payload);
};

export const removeAuroraHubZone = (state: RootState, payload: number): void => {
    removeFromState(state.auroraHubZones, payload);
};

export const setTimeZones = (state: RootState, payload: TimeZone[]): void => {
    state.timeZones = payload;
};

export const setUserGroups = (state: RootState, payload: UserGroup[]): void => {
    state.userGroups = payload;
};

export const removeUserGroup = (state: RootState, payload: number): void => {
    removeFromState(state.userGroups, payload);
};

export const saveUserGroup = (state: RootState, payload: UserGroup): void => {
    updateItemInState(state.userGroups, payload);
};

export const setUserUserGroups = (state: RootState, payload: UserUserGroup[]): void => {
    state.userUserGroups = payload;
};

export const setFarmGroups = (state: RootState, payload: FarmGroup[]): void => {
    state.farmGroups = payload;
};

export const removeFarmGroup = (state: RootState, payload: number): void => {
    removeFromState(state.farmGroups, payload);
};

export const saveFarmGroup = (state: RootState, payload: FarmGroup): void => {
    updateItemInState(state.farmGroups, payload);
};

export const setFarmFarmGroups = (state: RootState, payload: FarmFarmGroup[]): void => {
    state.farmFarmGroups = payload;
};

export const setFarmGroupUserGroups = (state: RootState, payload: FarmGroupUserGroup[]): void => {
    state.farmGroupUserGroups = payload;
};

export const setRoleUserGroups = (state: RootState, payload: RoleUserGroup[]): void => {
    state.roleUserGroups = payload;
};

export const setRoles = (state: RootState, payload: Role[]): void => {
    state.roles = payload;
};

export const removeRole = (state: RootState, payload: number): void => {
    removeFromState(state.roles, payload);
};

export const saveRole = (state: RootState, payload: Role): void => {
    updateItemInState(state.roles, payload);
};

export const setVehicleTypeUserGroups = (state: RootState, payload: VehicleTypeUserGroup[]): void => {
    state.vehicleTypeUserGroups = payload;
};
