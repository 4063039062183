import { render, staticRenderFns } from "./FarmAuroraHubZonesView.vue?vue&type=template&id=45931955&"
import script from "./FarmAuroraHubZonesView.vue?vue&type=script&lang=ts&"
export * from "./FarmAuroraHubZonesView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports