


























import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import VehicleForm from '@/components/vehicles/VehicleForm.vue';
import VehicleAction from '@/core/interfaces/VehicleAction';
import VehicleActionForm from '@/components/vehicle-actions/VehicleActionForm.vue';

@Component({
    components: { VehicleActionForm, VehicleForm, Overview },
})
export default class VehicleActionsView extends OverviewTable<VehicleAction> {
    @Getter('vehicleActions')
    vehicleActions!: VehicleAction[];

    @Action('deleteVehicleAction')
    deleteAction!: (id: number) => Promise<void>;

    editVehicleAction: Partial<VehicleAction> | null = null;

    get columns(): LelyTableColumnsConfig<VehicleAction> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
            },
        ];
    }

    openVehicleActionForm(vehicleAction: VehicleAction): void {
        this.editVehicleAction = vehicleAction;
        this.$bvModal.show('vehicle-action-form');
    }

    showCreateModal(): void {
        this.editVehicleAction = {};
        this.$bvModal.show('vehicle-action-form');
    }

    vehicleUpdated(): void {
        this.$bvModal.hide('vehicle-action-form');
        this.editVehicleAction = {};
    }
}

