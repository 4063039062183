















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class Overview extends Vue {
    @Prop({ required: true })
    name!: string;
}

