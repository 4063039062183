






































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import User from '@/core/interfaces/User';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import UserForm from '@/components/users/UserForm.vue';
import { VNode } from 'vue';
import { BBadge } from 'bootstrap-vue';
import UserTabs from '@/components/users/UserTabs.vue';
import Role from '@/core/interfaces/Role';
import UserGroup from '@/core/interfaces/UserGroup';

@Component({
    components: { UserTabs, Overview, UserForm },
})
export default class UsersView extends OverviewTable<User> {
    @Getter('users')
    users!: User[];

    @Action('deleteUser')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('rolesByUser')
    rolesByUser!: (id: number) => Role[];

    @Getter('userGroupsByUser')
    userGroupsByUser!: (id: number) => UserGroup[];

    editUser: Partial<User> | null = null;

    search = '';

    get columns(): LelyTableColumnsConfig<User> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'username',
                header: 'Username',
                sub: 'email',
            },
            {
                value: user => this.createGroups(user),
                header: 'Groups',
            },
            {
                value: user => this.createRoles(user),
                header: 'Roles',
                shrink: true,
            },
        ];
    }

    openUserForm(user: User): void {
        this.editUser = user;
        this.$bvModal.show('user-form');
    }

    showCreateModal(): void {
        this.editUser = {};
        this.$bvModal.show('user-form');
    }

    createGroups(user: User): VNode {
        const h = this.$createElement;

        return h(
            'ul',
            {
                class: 'list-unstyled',
            },
            this.userGroupsByUser(user.id).map(userGroup => h(
                'li',
                userGroup.name,
            )),
        );
    }

    createRoles(user: User): VNode {
        const h = this.$createElement;

        return h('ul', { class: 'list-unstyled' }, this.rolesByUser(user.id).map(role => h(
            'li',
            [h(BBadge, {
                class: 'mb-1 badge--secondary',
            }, [role.name])],
        )));
    }

    get usersFiltered(): User[] {
        return this.users.filter(user => user.username.toLowerCase().includes(this.search.toLowerCase()));
    }
}

