




























































import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action, Getter } from 'vuex-class';
import Farm from '@/core/interfaces/Farm';
import User from '@/core/interfaces/User';
import VehicleType from '@/core/interfaces/VehicleType';
import UserGroup from '@/core/interfaces/UserGroup';
import arrayUnique from 'array-unique';

@Component({
    components: { FormFooter },
})
export default class UserForm extends FormBase<User> {
    @Action('saveUser')
    saveAction!: (user: Partial<User>) => Promise<User>;

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Getter('userGroups')
    userGroups!: UserGroup[];

    @Getter('userGroupsByUser')
    userGroupsByUser!: (id: number) => UserGroup[];

    @Getter('farmsByUserGroup')
    farmsByUserGroup!: (id: number) => Farm[];

    @Getter('farms')
    farms!: Farm[];

    @Getter('farmById')
    farmById!: (id: number) => Farm;

    username = '';

    email = '';

    password = '';

    farmId: number | null = null;

    userGroupIds: number[] = [];

    @Watch('initial', { immediate: true })
    async setFromInitial(): Promise<void> {
        if (!this.initial) {
            return;
        }

        this.username = this.initial.username || '';
        this.email = this.initial.email || '';
        this.farmId = this.initial.farm_id || null;
        this.userGroupIds = this.initial.id
            ? arrayUnique(this.userGroupsByUser(this.initial.id).map((group) => group.id))
            : [];
    }

    async afterSave(user: User): Promise<void> {
        await this.$store.dispatch('saveUserUserGroups', {
            userId: user.id,
            userGroupIds: this.userGroupIds,
        });
    }

    get parseForm(): Partial<User> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            username: this.username,
            email: this.email,
            password: this.password || null,
            farm_id: this.farmId || null,
        };
    }

    get visibleFarms(): Farm[] {
        return arrayUnique([
            ...(this.farmId ? [this.farmById(this.farmId)] : []),
            ...this.userGroupIds.flatMap((id) => this.farmsByUserGroup(id)),
        ]).sort((a, b) => a.name.localeCompare(b.name));
    }

    submitted(): void {
        this.$emit('submitted');
    }
}

