import { LelyTableActionsConfig } from '@tec/frontend-vue-shared';
import Vue from 'vue';
import Component from 'vue-class-component';

@Component({})
export default class OverviewTable<T> extends Vue {
    actions: LelyTableActionsConfig<T> = [
        {
            name: 'delete',
            label: 'Delete',
            icon: 'lely-icon-trash',
            variant: 'warning',
        },
        {
            name: 'edit',
            label: 'Edit',
            icon: 'lely-icon-edit',
        },
    ];

    async confirmDelete(item: T, name: string): Promise<void> {
        const confirmed = await this.$bvModal.msgBoxConfirm(`Are you sure you want to delete ${name}?`, {
            centered: true,
            size: 'sm',
        });

        if (!confirmed) {
            return;
        }

        // @ts-ignore
        await this.deleteAction(item.id);
    }
}
