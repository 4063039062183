

































































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import VehicleType from '@/core/interfaces/VehicleType';
import VehicleTypeForm from '@/components/vehicle-types/VehicleTypeForm.vue';
import VehicleTypeEditDashboard from '@/components/vehicle-types/VehicleTypeEditDashboard.vue';
import VehicleTypeEditLocale from '@/components/vehicle-types/VehicleTypeEditLocale.vue';

@Component({
    components: {
        VehicleTypeEditLocale, VehicleTypeEditDashboard, VehicleTypeForm, Overview,
    },
})
export default class UsersView extends OverviewTable<VehicleType> {
    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Action('deleteVehicleType')
    deleteAction!: (id: number) => Promise<void>;

    editVehicleType: Partial<VehicleType> | null = null;

    dashboardVehicleType: VehicleType | null = null;

    localeVehicleType: VehicleType | null = null;

    search = '';

    get columns(): LelyTableColumnsConfig<VehicleType> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Vehicle type',
            },
            {
                value: 'can_combine_feed_types',
                header: 'Can combine feed types',
                shrink: true,
            },
        ];
    }

    get vehicleTypeActions(): LelyTableActionsConfig<VehicleType> {
        const actions = [...this.actions];

        actions.splice(1, 0, {
            name: 'edit-dashboard',
            icon: 'lely-icon-tv',
            label: 'Edit Dashboard',
        }, {
            name: 'edit-locales',
            icon: 'lely-icon-comment',
            label: 'Edit translations',
        });

        return actions;
    }

    openVehicleTypeForm(vehicleType: VehicleType): void {
        this.editVehicleType = vehicleType;
        this.$bvModal.show('vehicle-type-form');
    }

    showCreateModal(): void {
        this.editVehicleType = {};
        this.$bvModal.show('vehicle-type-form');
    }

    openDashboardModal(vehicleType: VehicleType): void {
        this.$bvModal.show('vehicle-type-dashboard');
        this.dashboardVehicleType = vehicleType;
    }

    openLocaleModal(vehicleType: VehicleType): void {
        this.$bvModal.show('locales-form');
        this.localeVehicleType = vehicleType;
    }

    vehicleTypeUpdated(): void {
        this.$bvModal.hide('vehicle-type-form');
        this.editVehicleType = {};
    }

    vehicleTypeDashboardUpdated(): void {
        this.$bvModal.hide('vehicle-type-dashboard');
        this.dashboardVehicleType = null;
    }

    get vehicleTypesFiltered(): VehicleType[] {
        return this.vehicleTypes.filter(vehicleType => vehicleType.name.toLowerCase().includes(this.search.toLowerCase()));
    }
}

