















import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';
import Application from '@/core/interfaces/Application';

@Component({
    components: { FormFooter },
})
export default class ApplicationForm extends FormBase<Application> {
    @Action('saveApplication')
    saveAction!: (barn: Partial<Application>) => Promise<Application>;

    code = '';

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.code = this.initial.code || '';
    }

    get parseForm(): Partial<Application> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            code: this.code,
        };
    }

    submitted(): void {
        this.$emit('submitted');
    }
}

