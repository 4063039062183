import Vue from 'vue';
import { Prop } from 'vue-property-decorator';
import Farm from '@/core/interfaces/Farm';
import { Getter } from 'vuex-class';
import Component from 'vue-class-component';

@Component({})
export default class UsesFarmId extends Vue {
    @Getter('farmById')
    farmById!: (id: number) => Farm;

    @Prop({ required: true })
    farmId!: number;

    get farm(): Farm {
        return this.farmById(Number(this.farmId));
    }
}
