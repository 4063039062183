

























import { Component } from 'vue-property-decorator';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { Action, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import UsesFarmId from '@/mixins/UsesFarmId';
import OverviewTable from '@/mixins/OverviewTable';
import Overview from '@/components/Overview.vue';
import ArbiterZone from '@/core/interfaces/ArbiterZone';
import ArbiterZoneForm from '@/components/farms/ArbiterZoneForm.vue';
import Barn from '@/core/interfaces/Barn';

@Component({
    components: { ArbiterZoneForm, Overview },
})
export default class FarmArbiterZonesView extends mixins<UsesFarmId, OverviewTable<ArbiterZone>>(UsesFarmId, OverviewTable) {
    @Action('deleteArbiterZone')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('arbiterZonesByFarm')
    arbiterZonesByFarm!: (id: number) => ArbiterZone[];

    @Getter('barnById')
    barnById!: (id: number) => Barn;

    editArbiterZone: Partial<ArbiterZone> | null = null;

    get arbiterZones(): ArbiterZone[] {
        return this.arbiterZonesByFarm(Number(this.farmId))
            .sort((a, b) => a.name.localeCompare(b.name))
            .sort((a, b) => (a.barn_id ? this.barnById(a.barn_id).name : '')
                .localeCompare((b.barn_id ? this.barnById(b.barn_id).name : '')));
    }

    get columns(): LelyTableColumnsConfig<ArbiterZone> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: zone => (zone.barn_id ? this.barnById(zone.barn_id).name : '-'),
                header: 'Barn',
                shrink: true,
            },
            {
                header: 'Name',
                value: 'name',
            },
        ];
    }

    showArbiterZone(arbiterZone: ArbiterZone): void {
        this.editArbiterZone = arbiterZone;
        this.$bvModal.show('arbiterZone-form');
    }

    arbiterZonesUpdated(): void {
        this.$bvModal.hide('arbiterZone-form');
        this.editArbiterZone = null;
    }

    addArbiterZone(): void {
        this.editArbiterZone = {
            farm_id: this.farmId,
        } as Partial<ArbiterZone>;

        this.$bvModal.show('arbiterZone-form');
    }
}

