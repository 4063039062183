
























import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import Locale from '@/core/interfaces/Locale';
import LocaleForm from '@/components/translations/LocaleForm.vue';

@Component({
    components: { LocaleForm, Overview },
})
export default class LocalesView extends OverviewTable<Locale> {
    @Getter('locales')
    locales!: Locale[];

    @Action('deleteLocale')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('localeById')
    localeById!: (id: number) => Locale;

    editLocale: Partial<Locale> | null = null;

    get columns(): LelyTableColumnsConfig<Locale> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'code',
                header: 'Code',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
            },
        ];
    }

    openLocaleForm(locale: Locale): void {
        this.editLocale = locale;
        this.$bvModal.show('locale-form');
    }

    showCreateModal(): void {
        this.editLocale = {};
        this.$bvModal.show('locale-form');
    }
}

