import { Component, Vue, Watch } from 'vue-property-decorator';
import JsonEditor from '@/components/JsonEditor.vue';
import FormFooter from '@/components/FormFooter.vue';
import axios from 'axios';

const spaces = 2;

@Component({
    components: { JsonEditor, FormFooter },
})
export default class EditsJson extends Vue {
    value: string | null = null;

    loading = false;

    saving = false;

    @Watch('url', { immediate: true })
    async fetch(): Promise<void> {
        if (!this.url) {
            this.value = '';
            return;
        }

        this.loading = true;

        try {
            this.value = JSON.stringify((await axios.get(this.url)).data, null, spaces);
        } catch (e) {
            this.value = '';
        } finally {
            this.loading = false;
        }
    }

    async save(value: string): Promise<void> {
        this.saving = true;

        try {
            if (this.deleteOnEmpty && value === '') {
                await axios.delete(this.url);
            } else {
                await axios.put(this.url, JSON.parse(String(value)));
            }
        } catch (err) {
            this.saving = false;
        }

        this.$emit('submitted');
    }

    get url(): string {
        return '';
    }

    get deleteOnEmpty(): boolean {
        return false;
    }
}
