




import { Component, Prop } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import JsonEditor from '@/components/JsonEditor.vue';
import { mixins } from 'vue-class-component';
import EditsJson from '@/mixins/EditsJson';

@Component({
    components: { JsonEditor, FormFooter },
})
export default class FarmVehicleTypeMapJson extends mixins(EditsJson) {
    @Prop({ type: Number, required: true })
    vehicleTypeId!: number;

    @Prop({ type: Number, required: true })
    farmId!: number;

    get url(): string {
        return `https://lely-alarmcenter-backend.azurewebsites.net/api/web/farms/${this.farmId}/map/${this.vehicleTypeId}/json`;
    }
}

