import { ActionContext } from 'vuex';
import RootState from '@/store/rootState';
import axios from 'axios';
import Farm from '@/core/interfaces/Farm';
import Barn from '@/core/interfaces/Barn';
import Fence from '@/core/interfaces/Fence';
import Vehicle from '@/core/interfaces/Vehicle';
import AvailableVehicleType from '@/core/interfaces/AvailableVehicleType';
import FeedType from '@/core/interfaces/FeedType';
import User from '@/core/interfaces/User';
import VehicleType from '@/core/interfaces/VehicleType';
import VehicleAction from '@/core/interfaces/VehicleAction';
import AvailableVehicleTypeAction from '@/core/interfaces/AvailableVehicleTypeAction';
import Application from '@/core/interfaces/Application';
import Locale from '@/core/interfaces/Locale';
import ArbiterZone from '@/core/interfaces/ArbiterZone';
import TimeZone from '@/core/interfaces/TimeZone';
import Role from '@/core/interfaces/Role';
import VehicleTypeUserGroup from '@/core/interfaces/VehicleTypeUserGroup';
import UserGroup from '@/core/interfaces/UserGroup';
import FarmGroup from '@/core/interfaces/FarmGroup';
import UserUserGroup from '@/core/interfaces/UserUserGroup';
import RoleUserGroup from '@/core/interfaces/RoleUserGroup';
import FarmGroupUserGroup from '@/core/interfaces/FarmGroupUserGroup';
import FarmFarmGroup from '@/core/interfaces/FarmFarmGroup';
import AuroraHubZone from '@/core/interfaces/AuroraHubZone';

const saveOrUpdate = async <T extends {
    id: number
}>(baseUrl: string, payload: Partial<T>): Promise<T> => {
    let res;
    if (payload.id) {
        res = (await axios.put<T>(`${baseUrl}/${payload.id}`, payload)).data;
    } else {
        res = (await axios.post<T>(baseUrl, payload)).data;
    }

    return res;
};

export const sync = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    await Promise.all([
        context.dispatch('syncApplications'),
        context.dispatch('syncAvailableVehicleTypeActions'),
        context.dispatch('syncAvailableVehicleTypes'),
        context.dispatch('syncBarns'),
        context.dispatch('syncFarms'),
        context.dispatch('syncFarmGroups'),
        context.dispatch('syncFarmFarmGroups'),
        context.dispatch('syncFarmGroupUserGroups'),
        context.dispatch('syncFeedTypes'),
        context.dispatch('syncFences'),
        context.dispatch('syncLocales'),
        context.dispatch('syncUsers'),
        context.dispatch('syncUserGroups'),
        context.dispatch('syncUserUserGroups'),
        context.dispatch('syncRoles'),
        context.dispatch('syncVehicleTypeUserGroups'),
        context.dispatch('syncRoleUserGroups'),
        context.dispatch('syncVehicleActions'),
        context.dispatch('syncVehicleTypes'),
        context.dispatch('syncVehicles'),
        context.dispatch('syncArbiterZones'),
        context.dispatch('syncAuroraHubZones'),
        context.dispatch('syncTimeZones'),
    ]);
};

export const syncFarms = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/farms');
    context.commit('setFarms', res.data);
};

export const syncFarmGroups = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/farm-groups');
    context.commit('setFarmGroups', res.data);
};

export const syncFarmFarmGroups = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/farms/groups');
    context.commit('setFarmFarmGroups', res.data);
};

export const syncFarmGroupUserGroups = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/farm-groups/farms');
    context.commit('setFarmGroupUserGroups', res.data);
};

export const syncRoleUserGroups = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/roles/groups');
    context.commit('setRoleUserGroups', res.data);
};

export const syncBarns = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/barns');
    context.commit('setBarns', res.data);
};

export const syncUsers = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/users');
    context.commit('setUsers', res.data);
};

export const syncUserGroups = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/user-groups');
    context.commit('setUserGroups', res.data);
};

export const syncUserUserGroups = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/users/groups');
    context.commit('setUserUserGroups', res.data);
};

export const syncVehicleTypes = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/vehicle-types');
    context.commit('setVehicleTypes', res.data);
};

export const syncVehicles = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/vehicles');
    context.commit('setVehicles', res.data);
};

export const syncAvailableVehicleTypes = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/farm-available-vehicle-types');
    context.commit('setAvailableVehicleTypes', res.data);
};

export const saveAvailableVehicleType = async(context: ActionContext<RootState, RootState>, payload: Partial<AvailableVehicleType>): Promise<void> => {
    const availableVehicleType = await saveOrUpdate<AvailableVehicleType>('/admin/farm-available-vehicle-types', payload);
    context.commit('saveAvailableVehicleType', availableVehicleType);
};

export const deleteAvailableVehicleType = async(context: ActionContext<RootState, RootState>, payload: number): Promise<void> => {
    const res = await axios.delete(`/admin/farm-available-vehicle-types/${payload}`);
    context.commit('removeAvailableVehicleType', res.data);
};
export const syncFences = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/fences');
    context.commit('setFences', res.data);
};

export const syncFeedTypes = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/feed-types');
    context.commit('setFeedTypes', res.data);
};

export const deleteFarm = async(context: ActionContext<RootState, RootState>, farmId: number): Promise<void> => {
    await axios.delete(`/admin/farms/${farmId}`);

    context.commit('removeFarm', farmId);
};

export const saveFarm = async(context: ActionContext<RootState, RootState>, payload: Partial<Farm>): Promise<Farm> => {
    const farm = await saveOrUpdate<Farm>('/admin/farms', payload);

    context.commit('saveFarm', farm);

    return farm;
};

export const saveBarn = async(context: ActionContext<RootState, RootState>, payload: Partial<Barn>): Promise<Barn> => {
    const barn = await saveOrUpdate<Barn>('/admin/barns', payload);

    context.commit('saveBarn', barn);

    return barn;
};

export const deleteFeedType = async(context: ActionContext<RootState, RootState>, id: number): Promise<void> => {
    await axios.delete(`/admin/feed-types/${id}`);

    context.commit('removeFeedType', id);
};

export const saveFeedType = async(context: ActionContext<RootState, RootState>, payload: Partial<FeedType>): Promise<FeedType> => {
    const feedType = await saveOrUpdate<FeedType>('/admin/feed-types', payload);

    context.commit('saveFeedType', feedType);

    return feedType;
};

export const deleteBarn = async(context: ActionContext<RootState, RootState>, barnId: number): Promise<void> => {
    await axios.delete(`/admin/barns/${barnId}`);

    context.commit('removeBarn', barnId);
};

export const saveFence = async(context: ActionContext<RootState, RootState>, payload: Partial<Fence>): Promise<Fence> => {
    const fence = await saveOrUpdate<Fence>('/admin/fences', payload);

    context.commit('saveFence', fence);

    return fence;
};

export const deleteFence = async(context: ActionContext<RootState, RootState>, fenceId: number): Promise<void> => {
    await axios.delete(`/admin/fences/${fenceId}`);

    context.commit('removeFence', fenceId);
};

export const saveVehicle = async(context: ActionContext<RootState, RootState>, payload: Partial<Vehicle>): Promise<Vehicle> => {
    const vehicle = await saveOrUpdate<Vehicle>('/admin/vehicles', payload);

    context.commit('saveVehicle', vehicle);

    return vehicle;
};

export const deleteVehicle = async(context: ActionContext<RootState, RootState>, vehicleId: number): Promise<void> => {
    await axios.delete(`/admin/vehicles/${vehicleId}`);

    context.commit('removeVehicle', vehicleId);
};

export const saveUser = async(context: ActionContext<RootState, RootState>, payload: Partial<User>): Promise<User> => {
    const user = await saveOrUpdate<User>('/admin/users', payload);

    context.commit('saveUser', user);

    return user;
};

export const deleteUser = async(context: ActionContext<RootState, RootState>, userId: number): Promise<void> => {
    await axios.delete(`/admin/users/${userId}`);

    context.commit('removeUser', userId);
};

export const saveVehicleType = async(context: ActionContext<RootState, RootState>, payload: Partial<VehicleType>): Promise<VehicleType> => {
    const vehicleType = await saveOrUpdate<VehicleType>('/admin/vehicle-types', payload);

    context.commit('saveVehicleType', vehicleType);

    return vehicleType;
};

export const deleteVehicleType = async(context: ActionContext<RootState, RootState>, vehicleTypeId: number): Promise<void> => {
    await axios.delete(`/admin/vehicle-types/${vehicleTypeId}`);

    context.commit('removeVehicleType', vehicleTypeId);
};

export const syncVehicleActions = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/vehicle-actions');
    context.commit('setVehicleActions', res.data);
};

export const syncAvailableVehicleTypeActions = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get('/admin/vehicle-type-actions');
    context.commit('setAvailableVehicleTypeActions', res.data);
};

export const deleteAvailableVehicleTypeAction = async(context: ActionContext<RootState, RootState>, vehicleTypeActionId: number): Promise<void> => {
    await axios.delete(`/admin/vehicle-type-actions/${vehicleTypeActionId}`);

    context.commit('removeAvailableVehicleTypeAction', vehicleTypeActionId);
};

export const saveAvailableVehicleTypeAction = async(
    context: ActionContext<RootState, RootState>,
    payload: Partial<AvailableVehicleTypeAction>,
): Promise<void> => {
    const res = await axios.post('/admin/vehicle-type-actions', payload);
    context.commit('saveAvailableVehicleTypeAction', res.data);
};

export const saveVehicleAction = async(context: ActionContext<RootState, RootState>, payload: Partial<VehicleAction>): Promise<VehicleAction> => {
    const vehicleAction = await saveOrUpdate<VehicleAction>('/admin/vehicle-actions', payload);

    context.commit('saveVehicleAction', vehicleAction);

    return vehicleAction;
};

export const deleteVehicleAction = async(context: ActionContext<RootState, RootState>, vehicleActionId: number): Promise<void> => {
    await axios.delete(`/admin/vehicle-actions/${vehicleActionId}`);

    context.commit('removeVehicleAction', vehicleActionId);
};

export const syncApplications = async(context: ActionContext<any, any>): Promise<void> => {
    const res = await axios.get('/admin/applications');
    context.commit('setApplications', res.data);
};

export const saveApplication = async(context: ActionContext<RootState, RootState>, payload: Partial<Application>): Promise<Application> => {
    const application = await saveOrUpdate<Application>('/admin/applications', payload);

    context.commit('saveApplication', application);

    return application;
};

export const deleteApplication = async(context: ActionContext<RootState, RootState>, applicationId: number): Promise<void> => {
    await axios.delete(`/admin/applications/${applicationId}`);

    context.commit('removeApplication', applicationId);
};

export const syncLocales = async(context: ActionContext<any, any>): Promise<void> => {
    const res = await axios.get('/admin/locales');
    context.commit('setLocales', res.data);
};

export const saveLocale = async(context: ActionContext<RootState, RootState>, payload: Partial<Locale>): Promise<Locale> => {
    const locale = await saveOrUpdate<Locale>('/admin/locales', payload);

    context.commit('saveLocale', locale);

    return locale;
};

export const deleteLocale = async(context: ActionContext<RootState, RootState>, localeId: number): Promise<void> => {
    await axios.delete(`/admin/locales/${localeId}`);

    context.commit('removeLocale', localeId);
};

export const syncArbiterZones = async(context: ActionContext<any, any>): Promise<void> => {
    const res = await axios.get('/admin/arbiter-zones');

    context.commit('setArbiterZones', res.data);
};

export const saveArbiterZone = async(context: ActionContext<RootState, RootState>, payload: Partial<ArbiterZone>): Promise<ArbiterZone> => {
    const arbiterZone = await saveOrUpdate<ArbiterZone>('/admin/arbiter-zones', payload);

    context.commit('saveArbiterZone', payload.id ? payload : arbiterZone);

    return arbiterZone;
};

export const deleteArbiterZone = async(context: ActionContext<RootState, RootState>, arbiterZoneId: number): Promise<void> => {
    await axios.delete(`/admin/arbiter-zones/${arbiterZoneId}`);

    context.commit('removeArbiterZone', arbiterZoneId);
};

export const syncAuroraHubZones = async(context: ActionContext<any, any>): Promise<void> => {
    const res = await axios.get('/admin/aurora-hub-zones');

    context.commit('setAuroraHubZones', res.data);
};

export const saveAuroraHubZone = async(context: ActionContext<RootState, RootState>, payload: Partial<AuroraHubZone>): Promise<AuroraHubZone> => {
    const auroraHubZone = await saveOrUpdate<AuroraHubZone>('/admin/aurora-hub-zones', payload);

    context.commit('saveAuroraHubZone', payload.id ? payload : auroraHubZone);

    return auroraHubZone;
};

export const deleteAuroraHubZone = async(context: ActionContext<RootState, RootState>, auroraHubZoneId: number): Promise<void> => {
    await axios.delete(`/admin/aurora-hub-zones/${auroraHubZoneId}`);

    context.commit('removeAuroraHubZone', auroraHubZoneId);
};

export const syncTimeZones = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get<TimeZone[]>('/admin/time-zones');
    context.commit('setTimeZones', res.data);
};

export const syncRoles = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get<Role[]>('/admin/roles');
    context.commit('setRoles', res.data);
};

export const syncVehicleTypeUserGroups = async(context: ActionContext<RootState, RootState>): Promise<void> => {
    const res = await axios.get<VehicleTypeUserGroup[]>('/admin/vehicle-types/groups');
    context.commit('setVehicleTypeUserGroups', res.data);
};

export const deleteUserGroup = async(context: ActionContext<RootState, RootState>, userGroupId: number): Promise<void> => {
    await axios.delete(`/admin/user-groups/${userGroupId}`);

    context.commit('removeUserGroup', userGroupId);
};

export const saveUserGroup = async(context: ActionContext<RootState, RootState>, payload: Partial<UserGroup>): Promise<UserGroup> => {
    const userGroup = await saveOrUpdate<UserGroup>('/admin/user-groups', payload);

    context.commit('saveUserGroup', userGroup);

    return userGroup;
};

export const deleteFarmGroup = async(context: ActionContext<RootState, RootState>, farmGroupId: number): Promise<void> => {
    await axios.delete(`/admin/farm-groups/${farmGroupId}`);

    context.commit('removeFarmGroup', farmGroupId);
};

export const saveFarmGroup = async(context: ActionContext<RootState, RootState>, payload: Partial<FarmGroup>): Promise<FarmGroup> => {
    const farmGroup = await saveOrUpdate<FarmGroup>('/admin/farm-groups', payload);

    context.commit('saveFarmGroup', farmGroup);

    return farmGroup;
};

export const deleteRole = async(context: ActionContext<RootState, RootState>, roleId: number): Promise<void> => {
    await axios.delete(`/admin/roles/${roleId}`);

    context.commit('removeRole', roleId);
};

export const saveRole = async(context: ActionContext<RootState, RootState>, payload: Partial<Role>): Promise<Role> => {
    const role = await saveOrUpdate<Role>('/admin/roles', payload);

    context.commit('saveRole', role);

    return role;
};

export const saveUserUserGroups = async(context: ActionContext<RootState, RootState>, payload: {
    userId: number,
    userGroupIds: number[]
}): Promise<void> => {
    const currentUserUserGroups = context.getters.userUserGroupsByUser(payload.userId) as UserUserGroup[];
    const currentUserGroupIds = currentUserUserGroups.map(userUserGroup => userUserGroup.user_group_id);

    const toAdd = payload.userGroupIds.filter(userGroupId => !currentUserGroupIds.includes(userGroupId));
    const toRemove = currentUserUserGroups
        .filter((userUserGroup: UserUserGroup) => !payload.userGroupIds.includes(userUserGroup.user_group_id))
        .map(userUserGroup => userUserGroup.id);

    await Promise.all([
        ...toAdd.map(userGroupId => axios.post('/admin/users/groups', {
            user_id: payload.userId,
            user_group_id: userGroupId,
        })),
        ...toRemove.map(userUserGroupId => axios.delete(`/admin/users/groups/${userUserGroupId}`)),
    ]);

    await context.dispatch('syncUserUserGroups');
};

export const saveRoleUserGroups = async(context: ActionContext<RootState, RootState>, payload: {
    userGroupId: number,
    roleIds: number[]
}): Promise<void> => {
    const currentRoleUserGroups = context.getters.roleUserGroupByUserGroup(payload.userGroupId) as RoleUserGroup[];
    const currentRoleIds = currentRoleUserGroups.map(roleUserGroup => roleUserGroup.role_id);

    const toAdd = payload.roleIds.filter(roleId => !currentRoleIds.includes(roleId));
    const toRemove = currentRoleUserGroups
        .filter((roleUserGroup: RoleUserGroup) => !payload.roleIds.includes(roleUserGroup.role_id))
        .map(roleUserGroup => roleUserGroup.id);

    await Promise.all([
        ...toAdd.map(roleId => axios.post('/admin/roles/groups', {
            user_group_id: payload.userGroupId,
            role_id: roleId,
        })),
        ...toRemove.map(roleUserGroupId => axios.delete(`/admin/roles/groups/${roleUserGroupId}`)),
    ]);

    await context.dispatch('syncRoleUserGroups');
};

export const saveFarmGroupUserGroups = async(context: ActionContext<RootState, RootState>, payload: {
    userGroupId: number,
    farmGroupIds: number[]
}): Promise<void> => {
    const currentFarmGroupUserGroups = context.getters.farmGroupUserGroupsByUserGroup(payload.userGroupId) as FarmGroupUserGroup[];
    const currentFarmGroupIds = currentFarmGroupUserGroups.map(farmGroupUserGroup => farmGroupUserGroup.farm_group_id);

    const toAdd = payload.farmGroupIds.filter(farmGroupId => !currentFarmGroupIds.includes(farmGroupId));
    const toRemove = currentFarmGroupUserGroups
        .filter((farmGroupUserGroup: FarmGroupUserGroup) => !payload.farmGroupIds.includes(farmGroupUserGroup.farm_group_id))
        .map(farmGroupUserGroup => farmGroupUserGroup.id);

    await Promise.all([
        ...toAdd.map(farmGroupId => axios.post('/admin/farm-groups/farms', {
            user_group_id: payload.userGroupId,
            farm_group_id: farmGroupId,
        })),
        ...toRemove.map(farmGroupUserGroupId => axios.delete(`/admin/farm-groups/farms/${farmGroupUserGroupId}`)),
    ]);

    await context.dispatch('syncFarmGroupUserGroups');
};

export const saveVehicleTypeUserGroups = async(context: ActionContext<RootState, RootState>, payload: {
    userGroupId: number,
    vehicleTypeIds: number[]
}): Promise<void> => {
    const currentVehicleTypeUserGroups = context.getters.vehicleTypeUserGroupsByUserGroup(payload.userGroupId) as VehicleTypeUserGroup[];
    const currentVehicleTypeIds = currentVehicleTypeUserGroups.map(vehicleTypeUserGroup => vehicleTypeUserGroup.vehicle_type_id);

    const toAdd = payload.vehicleTypeIds.filter(vehicleTypeId => !currentVehicleTypeIds.includes(vehicleTypeId));
    const toRemove = currentVehicleTypeUserGroups
        .filter((vehicleTypeUserGroup: VehicleTypeUserGroup) => !payload.vehicleTypeIds.includes(vehicleTypeUserGroup.vehicle_type_id))
        .map(vehicleTypeUserGroup => vehicleTypeUserGroup.id);

    await Promise.all([
        ...toAdd.map(vehicleTypeId => axios.post('/admin/vehicle-types/groups', {
            user_group_id: payload.userGroupId,
            vehicle_type_id: vehicleTypeId,
        })),
        ...toRemove.map(vehicleTypeUserGroupId => axios.delete(`/admin/vehicle-types/groups/${vehicleTypeUserGroupId}`)),
    ]);

    await context.dispatch('syncVehicleTypeUserGroups');
};

export const saveUserUserGroupsByUserGroup = async(context: ActionContext<RootState, RootState>, payload: {
    userGroupId: number,
    userIds: number[]
}): Promise<void> => {
    const currentUserUserGroups = context.getters.userUserGroupsByUserGroup(payload.userGroupId) as UserUserGroup[];
    const currentUserIds = currentUserUserGroups.map(userGroup => userGroup.id);

    const toAdd = payload.userIds.filter(userId => !currentUserIds.includes(userId));
    const toRemove = currentUserUserGroups
        .filter((userUserGroup: UserUserGroup) => !payload.userIds.includes(userUserGroup.user_id))
        .map(userUserGroup => userUserGroup.id);

    await Promise.all([
        ...toAdd.map(userId => axios.post('/admin/users/groups', {
            user_id: userId,
            user_group_id: payload.userGroupId,
        })),
        ...toRemove.map(userUserGroupId => axios.delete(`/admin/users/groups/${userUserGroupId}`)),
    ]);

    await context.dispatch('syncUserUserGroups');
};

export const saveFarmFarmGroupsByFarm = async(context: ActionContext<RootState, RootState>, payload: {
    farmId: number,
    farmGroupIds: number[]
}): Promise<void> => {
    const currentFarmFarmGroups = context.getters.farmFarmGroupsByFarm(payload.farmId) as FarmFarmGroup[];
    const currentFarmGroupIds = currentFarmFarmGroups.map(farmFarmGroup => farmFarmGroup.farm_group_id);

    const toAdd = payload.farmGroupIds.filter(farmGroupId => !currentFarmGroupIds.includes(farmGroupId));
    const toRemove = currentFarmFarmGroups
        .filter((farmFarmGroup: FarmFarmGroup) => !payload.farmGroupIds.includes(farmFarmGroup.farm_group_id))
        .map(farmFarmGroup => farmFarmGroup.id);

    await Promise.all([
        ...toAdd.map(farmGroupId => axios.post('/admin/farms/groups', {
            farm_id: payload.farmId,
            farm_group_id: farmGroupId,
        })),
        ...toRemove.map(farmFarmGroupId => axios.delete(`/admin/farms/groups/${farmFarmGroupId}`)),
    ]);

    await context.dispatch('syncFarmFarmGroups');
};

export const saveFarmFarmGroupsByFarmGroup = async(context: ActionContext<RootState, RootState>, payload: {
    farmGroupId: number,
    farmIds: number[]
}): Promise<void> => {
    const currentFarmFarmGroups = context.getters
        .farmFarmGroupsByFarmGroup(payload.farmGroupId) as FarmFarmGroup[];
    const currentFarmIds = currentFarmFarmGroups.map(farmFarmGroup => farmFarmGroup.farm_id);

    const toAdd = payload.farmIds.filter(farmId => !currentFarmIds.includes(farmId));
    const toRemove = currentFarmFarmGroups
        .filter((farmFarmGroup: FarmFarmGroup) => !payload.farmIds.includes(farmFarmGroup.farm_id))
        .map(farmFarmGroup => farmFarmGroup.id);

    await Promise.all([
        ...toAdd.map(farmId => axios.post('/admin/farms/groups', {
            farm_id: farmId,
            farm_group_id: payload.farmGroupId,
        })),
        ...toRemove.map(farmFarmGroupId => axios.delete(`/admin/farms/groups/${farmFarmGroupId}`)),
    ]);

    await context.dispatch('syncFarmFarmGroups');
};
