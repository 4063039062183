import axios from 'axios';
import router from '@/router';
import { LelyAuth } from '@tec/frontend-vue-shared';

axios.interceptors.response.use((response) => response, (error: any) => {
    if (error.response?.status === 401 && !router.currentRoute.meta?.guest) {
        LelyAuth.logout();

        router.push({ name: 'login' }).then();
    }

    return Promise.reject(error);
});
