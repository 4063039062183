






































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import Application from '@/core/interfaces/Application';
import ApplicationForm from '@/components/translations/ApplicationForm.vue';
import ApplicationTranslations from '@/components/translations/ApplicationTranslations.vue';

@Component({
    components: { ApplicationTranslations, ApplicationForm, Overview },
})
export default class ApplicationsView extends OverviewTable<Application> {
    @Getter('applications')
    applications!: Application[];

    @Action('deleteApplication')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('applicationById')
    applicationById!: (id: number) => Application;

    editApplication: Partial<Application> | null = null;

    translationsApplication: Application | null = null;

    get columns(): LelyTableColumnsConfig<Application> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'code',
                header: 'Code',
            },
        ];
    }

    get applicationActions(): LelyTableActionsConfig<Application> {
        const actions = [...this.actions];

        actions.splice(1, 0, {
            name: 'translations',
            label: 'Translations',
            icon: 'lely-icon-comment',
        });

        return actions;
    }

    openApplicationForm(application: Application): void {
        this.editApplication = application;
        this.$bvModal.show('application-form');
    }

    showCreateModal(): void {
        this.editApplication = {};
        this.$bvModal.show('application-form');
    }

    openTranslationsModal(application: Application): void {
        this.translationsApplication = application;
        this.$bvModal.show('translations-modal');
    }
}

