






















































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Farm from '@/core/interfaces/Farm';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import Vehicle from '@/core/interfaces/Vehicle';
import VehicleType from '@/core/interfaces/VehicleType';
import VehicleForm from '@/components/vehicles/VehicleForm.vue';
import { format, isAfter, parseISO } from 'date-fns';
import Barn from '@/core/interfaces/Barn';

@Component({
    components: { VehicleForm, Overview },
})
export default class VehiclesView extends OverviewTable<Vehicle> {
    @Getter('vehicles')
    vehicles!: Vehicle[];

    @Getter('farms')
    farms!: Farm[];

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    @Getter('farmById')
    farmById!: (id: number) => Farm;

    @Getter('barnById')
    barnById!: (id: number) => Barn;

    @Action('deleteVehicle')
    deleteAction!: (id: number) => Promise<void>;

    farmId: number | null = null;

    vehicleTypeId: number | null = null;

    editVehicle: Partial<Vehicle> | null = null;

    search = '';

    mounted(): void {
        if (this.$route.query.farmId) {
            this.farmId = Number(this.$route.query.farmId);
        }

        if (this.$route.query.vehicleTypeId) {
            this.vehicleTypeId = Number(this.$route.query.vehicleTypeId);
        }
    }

    get columns(): LelyTableColumnsConfig<Vehicle> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
                sub: vehicle => this.subName(vehicle),
            },
            {
                value: vehicle => this.farmById(vehicle.farm_id).name,
                header: 'Farm',
                sub: vehicle => (vehicle.barn_id ? this.barnById(vehicle.barn_id).name : null),
            },
            {
                value: 'sim',
                header: 'Sim',
            },
            {
                value: 'bt_classic',
                header: 'BT Classic',
            },
            {
                value: vehicle => (vehicle.valid_to ? isAfter(parseISO(vehicle.valid_to), new Date()) : false),
                sub: vehicle => (vehicle.valid_to ? `until ${format(parseISO(vehicle.valid_to), 'dd-MM-yyyy')}` : ''),
                header: 'Token valid',
            },
        ];
    }

    get vehicleActions(): LelyTableActionsConfig<Vehicle> {
        const actions = [...this.actions];

        actions.splice(1, 0, {
            name: 'copy-token',
            icon: 'lely-icon-copy',
            label: 'Copy token',
        });

        return actions;
    }

    get filteredVehicles(): Vehicle[] {
        return this.vehicles
            .filter(vehicle => vehicle.name.toLowerCase().includes(this.search.toLowerCase()))
            .filter(vehicle => {
                if (this.farmId && this.vehicleTypeId) {
                    return vehicle.farm_id === this.farmId && vehicle.vehicle_type_id === this.vehicleTypeId;
                }

                if (this.farmId) {
                    return vehicle.farm_id === this.farmId;
                }

                if (this.vehicleTypeId) {
                    return vehicle.vehicle_type_id === this.vehicleTypeId;
                }

                return true;
            });
    }

    openVehicleForm(vehicle: Vehicle): void {
        this.editVehicle = vehicle;
        this.$bvModal.show('vehicle-form');
    }

    showCreateModal(): void {
        this.editVehicle = {};
        this.$bvModal.show('vehicle-form');
    }

    vehicleUpdated(): void {
        this.$bvModal.hide('vehicle-form');
        this.editVehicle = {};
    }

    copyToken(vehicle: Vehicle): void {
        navigator.clipboard.writeText(String(vehicle.access_token));
    }

    subName(vehicle: Vehicle): string {
        const vehicleType = this.vehicleTypeById(vehicle.vehicle_type_id);

        if (vehicleType.name === 'mfr') {
            return `${vehicleType.name} ${vehicle.mfr_id}`;
        }

        return vehicleType.name;
    }
}

