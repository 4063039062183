

























import { Component, Prop } from 'vue-property-decorator';
import Barn from '@/core/interfaces/Barn';
import FormFooter from '@/components/FormFooter.vue';
import Fence from '@/core/interfaces/Fence';
import { Action, Getter } from 'vuex-class';
import OverviewTable from '@/mixins/OverviewTable';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import FenceForm from '@/components/farms/FenceForm.vue';
import Overview from '@/components/Overview.vue';

@Component({
    components: { Overview, FormFooter, FenceForm },
})
export default class BarnFences extends OverviewTable<Fence> {
    @Prop({ required: true })
    barn!: Barn;

    @Getter('fencesByBarn')
    fencesByBarn!: (barnId: number) => Fence[];

    @Getter('fenceById')
    fenceById!: (id: number) => Fence;

    @Action('deleteFence')
    deleteAction!: (id: number) => Fence;

    editFence: Partial<Fence> | null = null;

    get fences(): Fence[] {
        if (!this.barn) {
            return [];
        }

        return this.fencesByBarn(this.barn.id);
    }

    get columns(): LelyTableColumnsConfig<Fence> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                header: 'Fence',
                value: 'name',
            },
        ];
    }

    openFenceForm(fence: Fence): void {
        this.editFence = fence;
        this.$bvModal.show('fence-form');
    }

    fenceUpdated(): void {
        this.$bvModal.hide('fence-form');
        this.editFence = null;
    }

    addFence(): void {
        this.editFence = {
            barn_id: this.barn.id,
            vector_fence_id: null,
        } as Partial<Fence>;

        this.$bvModal.show('fence-form');
    }
}

