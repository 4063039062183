
























import { Component } from 'vue-property-decorator';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { Action, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import UsesFarmId from '@/mixins/UsesFarmId';
import OverviewTable from '@/mixins/OverviewTable';
import Overview from '@/components/Overview.vue';
import FeedType from '@/core/interfaces/FeedType';
import FeedTypeForm from '@/components/farms/FeedTypeForm.vue';
import VehicleType from '@/core/interfaces/VehicleType';

@Component({
    components: { Overview, FeedTypeForm },
})
export default class FarmFeedTypesView extends mixins<UsesFarmId, OverviewTable<FeedType>>(UsesFarmId, OverviewTable) {
    @Action('deleteFeedType')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('feedTypesByFarm')
    feedTypesByFarm!: (id: number) => FeedType[];

    @Getter('vehicleTypeById')
    vehicleTypeById!: (id: number) => VehicleType;

    editFeedType: Partial<FeedType> | null = null;

    get feedTypes(): FeedType[] {
        return this.feedTypesByFarm(Number(this.farmId));
    }

    get columns(): LelyTableColumnsConfig<FeedType> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                header: 'Name',
                value: 'name',
            },
            {
                header: 'Vehicle type',
                value: feedType => this.vehicleTypeById(feedType.vehicle_type_id)?.name || '',
            },
        ];
    }

    showFeedType(feedType: FeedType): void {
        this.editFeedType = feedType;
        this.$bvModal.show('feedType-form');
    }

    feedTypesUpdated(): void {
        this.$bvModal.hide('feedType-form');
        this.editFeedType = null;
    }

    addFeedType(): void {
        this.editFeedType = {
            farm_id: this.farmId,
        } as Partial<FeedType>;

        this.$bvModal.show('feedType-form');
    }
}

