













































import { Component } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import Overview from '@/components/Overview.vue';
import OverviewTable from '@/mixins/OverviewTable';
import FarmGroup from '@/core/interfaces/FarmGroup';
import FarmGroupForm from '@/components/farms/FarmGroupForm.vue';

@Component({
    components: { Overview, FarmGroupForm },
})
export default class FarmGroupsView extends OverviewTable<FarmGroup> {
    @Getter('farmGroups')
    farmGroups!: FarmGroup[];

    @Action('deleteFarmGroup')
    deleteAction!: (id: number) => Promise<void>;

    editFarmGroup: Partial<FarmGroup> | null = null;

    search = '';

    get columns(): LelyTableColumnsConfig<FarmGroup> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                value: 'name',
                header: 'Name',
                sub: farmGroup => `${this.$store.getters.farmsByFarmGroup(farmGroup.id).length} farms`,
            },
        ];
    }

    openForm(farmGroup: FarmGroup): void {
        this.editFarmGroup = farmGroup;
        this.$bvModal.show('farm-group-form');
    }

    showCreateModal(): void {
        this.editFarmGroup = {};
        this.$bvModal.show('farm-group-form');
    }

    get farmGroupsFiltered(): FarmGroup[] {
        return this.farmGroups.filter(farmGroup => farmGroup.name.toLowerCase().includes(this.search.toLowerCase()));
    }
}

