





























































import { Component, Vue, Watch } from 'vue-property-decorator';
import { Mutation, State } from 'vuex-class';
import { LelyApp } from '@tec/frontend-vue-shared';

@Component({})
export default class App extends Vue {
    @State('errors')
    errors!: string[];

    @Mutation('resetErrors')
    resetErrors!: () => void;

    get loginPage(): boolean {
        return this.$route.name === 'login';
    }

    get app(): LelyApp {
        return LelyApp.HMF_ADMIN;
    }

    @Watch('errors')
    async showErrors(): Promise<void> {
        if (this.errors.length === 0) {
            return;
        }

        const messages = this.errors.map(err => this.$createElement('div', { class: 'alert alert-danger' }, err));

        this.resetErrors();

        await this.$bvModal.msgBoxOk(messages, {
            title: 'Errors',
            okTitle: 'Close',
            okVariant: 'primary',
        });
    }
}

