



















import { Component, Watch } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import FormBase from '@/mixins/FormBase';
import { Action } from 'vuex-class';
import Locale from '@/core/interfaces/Locale';

@Component({
    components: { FormFooter },
})
export default class LocaleForm extends FormBase<Locale> {
    @Action('saveLocale')
    saveAction!: (barn: Partial<Locale>) => Promise<Locale>;

    code = '';

    name = '';

    @Watch('initial', { immediate: true })
    setFromInitial(): void {
        if (!this.initial) {
            return;
        }

        this.code = this.initial.code || '';
        this.name = this.initial.name || '';
    }

    get parseForm(): Partial<Locale> {
        return {
            ...this.initial,
            id: this.initial?.id || undefined,
            code: this.code,
            name: this.name,
        };
    }

    submitted(): void {
        this.$emit('submitted');
    }
}

