
































import { Component } from 'vue-property-decorator';
import UsesFarmId from '@/mixins/UsesFarmId';
import { Getter } from 'vuex-class';
import Farm from '@/core/interfaces/Farm';
import { BreadcrumbInterface } from '@tec/frontend-vue-shared';

@Component({
    components: {},
})
export default class FarmView extends UsesFarmId {
    @Getter('farms')
    farms!: Farm[];

    get breadcrumbs(): BreadcrumbInterface[] {
        return [
            {
                label: 'Farms',
                location: {
                    name: 'farms.index',
                },
            },
            {
                location: {
                    name: 'farms.item.form',
                    params: { farmId: String(this.farmId) },
                },
                label: this.farm.name,
                options: this.farms.map(farm => ({
                    label: farm.name,
                    location: {
                        name: 'farms.item.form',
                        params: {
                            farmId: String(farm.id),
                        },
                    },
                })),
            }];
    }
}

