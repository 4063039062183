































































































import { Component } from 'vue-property-decorator';
import { LelyTableActionsConfig, LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { Action, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import UsesFarmId from '@/mixins/UsesFarmId';
import OverviewTable from '@/mixins/OverviewTable';
import Overview from '@/components/Overview.vue';
import FeedTypeForm from '@/components/farms/FeedTypeForm.vue';
import VehicleType from '@/core/interfaces/VehicleType';
import AvailableVehicleType from '@/core/interfaces/AvailableVehicleType';
import FarmVehicleTypeMapJson from '@/components/farms/FarmVehicleTypeMapJson.vue';
import FarmVehicleTypeMapBackground from '@/components/farms/FarmVehicleTypeMapBackground.vue';
import FormFooter from '@/components/FormFooter.vue';
import FarmVehicleTypeActions from '@/components/farms/FarmVehicleTypeActions.vue';
import AvailableVehicleTypeAction from '@/core/interfaces/AvailableVehicleTypeAction';
import VehicleAction from '@/core/interfaces/VehicleAction';
import FarmVehicleTypeSettings from '@/components/farms/FarmVehicleTypeSettings.vue';
import Vehicle from '@/core/interfaces/Vehicle';

@Component({
    components: {
        FarmVehicleTypeSettings,
        FarmVehicleTypeActions,
        FormFooter,
        FarmVehicleTypeMapBackground,
        FarmVehicleTypeMapJson,
        Overview,
        FeedTypeForm,
    },
})
export default class FarmVehicleTypesView extends mixins<UsesFarmId, OverviewTable<AvailableVehicleType>>(UsesFarmId, OverviewTable) {
    @Action('deleteAvailableVehicleType')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('vehicleTypes')
    vehicleTypes!: VehicleType[];

    @Getter('availableVehicleTypesByFarm')
    availableVehicleTypesByFarm!: (farmId: number) => AvailableVehicleType[];

    @Getter('vehicleTypeById')
    vehicleTypeById!: (vehicleTypeId: number) => VehicleType;

    @Getter('availableActionsByAvailableVehicleType')
    availableActionsByAvailableVehicleType!: (availableVehicleType: number) => AvailableVehicleTypeAction[];

    @Getter('vehicleActionById')
    vehicleActionById!: (vehicleActionId: number) => VehicleAction;

    @Action('saveAvailableVehicleType')
    saveAvailableVehicleType!: (payload: Partial<AvailableVehicleType>) => Promise<void>;

    @Getter('vehiclesByFarmAndVehicleType')
    vehiclesByFarmAndVehicleType!: (farmId: number, vehicleTypeId: number) => Vehicle[];

    mapJsonFarmVehicleType: AvailableVehicleType | null = null;

    mapBackgroundFarmVehicleType: AvailableVehicleType | null = null;

    actionsFarmVehicleType: AvailableVehicleType | null = null;

    settingsAvailableVehicleType: AvailableVehicleType | null = null;

    addVehicleTypeId: number | null = null;

    get availableVehicleTypes(): AvailableVehicleType[] {
        return this.availableVehicleTypesByFarm(Number(this.farmId));
    }

    get vehicleTypeOptions(): VehicleType[] {
        return this.vehicleTypes.filter(vehicleType => !this.availableVehicleTypes
            .some(item => item.vehicle_type_id === vehicleType.id));
    }

    get vehicleTypeActions(): LelyTableActionsConfig<AvailableVehicleType> {
        return [
            {
                name: 'actions',
                icon: 'lely-icon-activities',
                label: 'Actions',
            },
            {
                name: 'map-json',
                icon: 'lely-icon-map',
                label: 'Map JSON',
            },
            {
                name: 'map-background',
                icon: 'lely-icon-picture',
                label: 'Map background',
            },
            {
                name: 'settings',
                icon: 'lely-icon-edit',
                label: 'Settings',
            },
            {
                name: 'show-vehicles',
                label: vehicleType => `Show vehicles (${this.vehiclesByFarmAndVehicleType(Number(this.farmId), vehicleType.vehicle_type_id).length})`,
                show: vehicleType => this.vehiclesByFarmAndVehicleType(Number(this.farmId), vehicleType.vehicle_type_id).length > 0,
                icon: 'lely-icon-traffic',
            },
            {
                name: 'delete',
                label: 'Delete',
                icon: 'lely-icon-trash',
                variant: 'warning',
            },
        ];
    }

    get columns(): LelyTableColumnsConfig<AvailableVehicleType> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                header: 'Vehicle type',
                value: row => this.vehicleTypeById(row.vehicle_type_id)?.name || '',
                sub: row => this.availableActionsByAvailableVehicleType(row.id)
                    .map(item => this.vehicleActionById(item.vehicle_action_id)?.name || '-')
                    .join(', '),
            },
        ];
    }

    mapJsonUpdated(): void {
        this.$bvModal.hide('map-json-modal');
    }

    mapBackgroundUpdated(): void {
        this.$bvModal.hide('map-background-modal');
    }

    showMapJsonModal(item: AvailableVehicleType) {
        this.mapJsonFarmVehicleType = item;
        this.$bvModal.show('map-json-modal');
    }

    showMapBackgroundModal(item: AvailableVehicleType) {
        this.mapBackgroundFarmVehicleType = item;
        this.$bvModal.show('map-background-modal');
    }

    showSettingsModal(item: AvailableVehicleType) {
        this.settingsAvailableVehicleType = item;
        this.$bvModal.show('settings-modal');
    }

    showActionsModal(item: AvailableVehicleType) {
        this.actionsFarmVehicleType = item;
        this.$bvModal.show('actions-modal');
    }

    actionsUpdated() {
        this.$bvModal.hide('actions-modal');
    }

    async addVehicleType(): Promise<void> {
        if (!this.addVehicleTypeId) {
            return;
        }

        await this.saveAvailableVehicleType({
            farm_id: Number(this.farmId),
            vehicle_type_id: this.addVehicleTypeId,
        });

        this.$bvModal.hide('add-modal');
    }
}

