







import { Component, Prop, Vue } from 'vue-property-decorator';
import FormFooter from '@/components/FormFooter.vue';
import JsonEditor from '@/components/JsonEditor.vue';
import axios from 'axios';

@Component({
    components: { JsonEditor, FormFooter },
})
export default class FarmVehicleTypeMapBackground extends Vue {
    @Prop({ type: Number, required: true })
    vehicleTypeId!: number;

    @Prop({ type: Number, required: true })
    farmId!: number;

    background = '';

    async mounted(): Promise<void> {
        try {
            const { data } = await axios.get(this.url, {
                responseType: 'blob',
            });
            this.background = URL.createObjectURL(data);
        } catch (e) {
            this.background = '';
        }
    }

    async submit(): Promise<void> {
        this.$emit('submitted');
    }

    get url(): string {
        return `https://lely-alarmcenter-backend.azurewebsites.net/api/web/farms/${this.farmId}/map/${this.vehicleTypeId}/background`;
    }
}

