

























import { Component } from 'vue-property-decorator';
import { LelyTableColumnsConfig } from '@tec/frontend-vue-shared';
import { Action, Getter } from 'vuex-class';
import { mixins } from 'vue-class-component';
import UsesFarmId from '@/mixins/UsesFarmId';
import OverviewTable from '@/mixins/OverviewTable';
import Overview from '@/components/Overview.vue';
import AuroraHubZone from '@/core/interfaces/AuroraHubZone';
import AuroraHubZoneForm from '@/components/farms/AuroraHubZoneForm.vue';

@Component({
    components: { AuroraHubZoneForm, Overview },
})
export default class FarmAuroraHubZonesView extends mixins<UsesFarmId, OverviewTable<AuroraHubZone>>(UsesFarmId, OverviewTable) {
    @Action('deleteAuroraHubZone')
    deleteAction!: (id: number) => Promise<void>;

    @Getter('auroraHubZonesByFarm')
    auroraHubZonesByFarm!: (id: number) => AuroraHubZone[];

    editAuroraHubZone: Partial<AuroraHubZone> | null = null;

    get auroraHubZones(): AuroraHubZone[] {
        return this.auroraHubZonesByFarm(Number(this.farmId));
    }

    get columns(): LelyTableColumnsConfig<AuroraHubZone> {
        return [
            {
                value: 'id',
                header: 'ID',
                shrink: true,
            },
            {
                header: 'Name',
                value: 'name',
            },
        ];
    }

    showAuroraHubZone(auroraHubZone: AuroraHubZone): void {
        this.editAuroraHubZone = auroraHubZone;
        this.$bvModal.show('aurora-hub-zone-form');
    }

    auroraHubZonesUpdated(): void {
        this.$bvModal.hide('aurora-hub-zone-form');
        this.editAuroraHubZone = null;
    }

    addAuroraHubZone(): void {
        this.editAuroraHubZone = {
            farm_id: this.farmId,
        } as Partial<AuroraHubZone>;

        this.$bvModal.show('aurora-hub-zone-form');
    }
}

