



















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {},
})
export default class FarmForm extends Vue {
    @Prop({ default: false, type: Boolean })
    saving!: boolean;

    @Prop({ default: false, type: Boolean })
    showDelete!: boolean;

    @Prop({ default: false, type: Boolean })
    disableSave!: boolean;

    @Prop({ default: true, type: Boolean })
    showReset!: boolean;
}
